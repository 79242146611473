import {AxiosResponse} from "axios";
import moment, {Moment} from "moment";
import {Runtime} from "inspector";
import {Dropdown} from "react-bootstrap";
import React from "react";

export interface API_CALL {
    status: NET_STATUS;
    message?: string;
    data: AxiosResponse<any>;
    error: any;
    onChange: any;
}

export enum NET_STATUS {
    'NOT_STARTED',
    'LOADING',
    'SUCCESS',
    'ERROR'
}

export enum PARAMETERS {
    'SHIFTS' = 2,
    'PELET_TYPE' = 3,
    'BAG_TYPE' = 4,
    'FADROMI' = 5,
    'MOTOCARI' = 6,
    'BRICKS' = 7,
    'EXTRUDER_REASONS' = 8,
    'ROBOT_REASONS' = 9,
    'WASTE_REASONS' = 10,
    'NOT_GOOD_REASONS' = 11,
    'EXTR_WASTE_REASONS' = 12,
    'CLAY_REASONS' = 13,
    'CLIENTS' = 14,
}

// export enum SALE_TYPE {
//     'bricks_w_env'= 'тухли с фактура',
//     'bricks_wo_env' = 'тухли с касов бон',
//     'peleti_w_env' = 'пелети с фактура',
//     'peleti_wo_env' = 'пелети с касов бон',
//     'others' = 'други',
// }

export enum SALE_TYPE {
    'bricks_w_env'= 'тухли с фактура',
    'bricks_wo_env' = 'тухли с отложено плащане',
    'peleti_w_env' = 'пелети с фактура',
    'peleti_wo_env' = 'пелети с отложено плащане',
    'others' = 'други',
}

export enum PAY_TYPE {
    'BANKA' = 'BANKA',
    'CASH' = 'CASH',
    'CASH_INV' = 'CASH_INV',
    'AVANS_BANKA' = 'AVANS_BANKA',
    'AVANS_CASH' = 'AVANS_CASH',
    'CREDIT' = 'CREDIT',
    'LOGISTIC' = 'LOGISTIC',
    'PALET' = 'PALET',
    'PALET_ISO' = 'PALET_ISO',
    'PRICELIST' = 'PRICELIST',
    'NOT_PAID' = 'NOT_PAID',
}

export interface UserModule {
    access_rights: 'NONE' | 'READ' | 'WRITE',
    app_modules_id: number,
    module: AppModule
}

export enum CALENDAR_MODE {
    'MACHINES' = 'MACHINES',
    'ADMINISTRATIVE' = 'ADMINISTRATIVE',
}

export const MONTHS_NAMES: string[] = ['Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'];
export const SHORT_MONTHS_NAMES: string[] = ['ЯНУ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ЮНИ', 'ЮЛИ', 'АВГ', 'СЕП', 'ОКТ', 'НОЕ', 'ДЕК'];


export interface AppModule {
    id: number,
    name: string,
    url: string
    top_modul?: number,
    owner?: number,
    group_name: string,
    order_pos: number,
    deleted_at?: number
}

export interface Shift {
    id: number,
    name: string
}

export interface Department {
    id: number,
    name: string
}

export interface Account {
    id: number,
    name: string,
    email: string,
    department_id: number,
    department: string,
    position: string,
    birth_date: string,
    greeting_card: string,
    first_date: string,
    is_admin: 'Y'|'N',
    is_executor: 'Y'|'N',
    is_owner: 'Y'|'N',
    modules: UserModule[],
    data: any[],
    hasBonus: boolean,
}

export interface PropertyValue {
    id: number,
    value: string,
    description: any,
}

export enum PropertyDescriptorType {
    string = "string",
    number = "number",
    boolean = "boolean",
    date = "date",
    timestamp = "timestamp",
}

export interface PropertyDescriptor {
    name: string,
    title: string,
    type: PropertyDescriptorType,
}

export interface Property {
    id: number,
    name: string,
    valuesJSON: string,
    descriptorJSON: string,
    values: PropertyValue[],
    descriptor: PropertyDescriptor[],
}

export interface Pelet {
    id: number,
    value: string
}

export interface Bag {
    id: number,
    value: string
}

export interface Brick {
    id: number,
    value: string
}

export interface Charging {
    chargeTime: string,
    pelet: number,
    bagType: number,
    bagQty: number,
    notes: string
}

export interface OvenProduction {
    brick_id: number,
    wagon_qty: number,
}
export interface OvenTemp {
    wagon: number,
    time: string,
    temp_1_min: number,
    temp_1_max: number,
    temp_2_min: number,
    temp_2_max: number,
    temp_3_min: number,
    temp_3_max: number,
    temp_4_min: number,
    temp_4_max: number,
    temp_5_min: number,
    temp_5_max: number,
    temp_6_min: number,
    temp_6_max: number,
}

export interface Repair {
    id?: number,
    repairType: REPAIR_TYPE,
    fromTime: string,
    toTime: string,
    comments: string
}


export interface BaseReportInfo {
    report_date: string;
    shift_id: number;
    from_time: string;
    to_time: string;
    man_cnt: number;
    comments: string;
}


export interface Report {
    id: number;
    user: Account;
    base_info: BaseReportInfo;
    request_data: string;
}

export interface RefuelingReport extends Report {
    authors: string;
}

export interface HeaterReport extends Report{
    heater_number: number,
    cleanups: string[],
    chargings: Charging[],
}

export interface OrderingReport extends Report {
}

export interface ExtruderReport extends Report{
    production: ExtruderProduction[],
    mushtukCorrections: ExtruderMushtukCorrection[],
}

export enum REPAIR_TYPE {
    'HEATER'= 'Печка',
    'GRINDER'='Мелене',
    'EXTRUDER'='Екструдер',
    'ORDERING'='Редене',
    'OVEN'='Пещ',
    'UNLOADING'='Разтоварване',
    'DRIER'='Сушилня',
}

export interface RepairsReport extends Report{
    repair_type: REPAIR_TYPE,
    repairs: Repair[],
}

export interface OvenReport extends Report{
    temps: OvenTempsRecord[],
    chargings: Charging[],
    heater_chargings: Charging[],
    production: OvenProduction[],
}


export interface GrindignLoading {
    id: number,
    loader_type: LoaderType,
    loaderIdx: number,
    qty: number
}

export enum LoaderType {
    'FADROMA' = 'FADROMA', 'MOTOCAR' = 'MOTOCAR'
}

export interface ExtruderQuantity {
    brick1: Brick,
    brick2: Brick,
    wagonsQty: number,
    wasteQty: number,
    reason1: PropertyValue,
    reason1_percent: number,
    reason2: PropertyValue,
    reason2_percent: number,
    reason3: PropertyValue,
    reason3_percent: number,
}

export interface ExtruderProduction {
    time: string,
    brick: Brick,
    vacuum: number,
    pressure_from: number,
    pressure_to: number,
    wagonsQty: number,
    waste: number,
    layers_waste: number,
    reason1: number,
    reason1_percent: number,
    reason2: number,
    reason2_percent: number,
    reason3: number,
    reason3_percent: number,
}

export interface OvenTempsRecord {
    time: string,
    wagonNum: number,
    temp_1_1: number,
    temp_1_2: number,
    temp_2_1: number,
    temp_2_2: number,
    temp_3_1: number,
    temp_3_2: number,
    temp_4_1: number,
    temp_4_2: number,
    temp_5_1: number,
    temp_5_2: number,
    temp_6_1: number,
    temp_6_2: number,
}

export interface ExtruderMushtukCorrection {
    time: string,
    description: string,
}

export interface ExtruderReport {

}

export interface SalePayment {
    date: Date;
    amount: number;
    type: string;
    amountInput: string;
    isAvans: boolean;
}

export interface Sale {
    id: number | null,
    sale_type: SALE_TYPE | null,
    activePriceList: string | null,
    sales_number: number | null,
    sales_date:	Moment | null,
    client_id: number | null,
    client: string | null,
    client_city: string | null,
    returned: number | null,
    returned_iso: number | null,
    driver: string | null,
    driver_phone: string | null,
    license_plate: string | null,
    store_manager: string | null,
    currier: string | null,
    loader_name: string | null,
    items?: SaleItem[] | null,
    price: number | null,
    priceType: BankaCashType,
    invPaletsPriceType: BankaCashType,
    invPaletsPriceType_iso: BankaCashType,
    incl_logistic: boolean | null,
    inv_number: string | null,
    inv_number_cash: string | null,
    pay_type: PAY_TYPE | null,
    inv_price: number | null,
    inv_price_cash: number | null,
    inv_payed: number | null,
    credit_payed: number | null,
    payed_by_avans: boolean | number,
    payed_by_credit: boolean | number,
    is_payed: boolean | number,
    request_data: string | null,
    payments: SalePayment[],
    total_payments: number,
    total_price: number,
    inv_logistic_price: number | null,
    inv_palets_price: number | null,
    inv_palets_price_iso: number | null,
    inv_logistic_payed: number | null,
    inv_palets_payed: number | null,
    inv_palets_payed_iso: number | null,
    notes: string,
    inv_logistic_is_avans: boolean | number,
    inv_palets_is_avans: boolean | number,
    inv_palets_is_avans_iso: boolean | number,
    recordVersion: "v1" | "v2",
}


export interface Logistic {
    id: number,
    name: string,
    drivers: Driver[];
}

export interface Driver {
    name: string;
    phone: string;
    reg_nomer: string;
}

export interface Client {
    id: number,
    name: string,
    isForeign: number,
    cities: City[];
    balance_avans_bank: number,
    balance_avans_cash: number,
    balance_bank: number,
    balance_cash: number,
    balance_credit: number,
    balance_logistics: number,
    balance_palet: number,
    balance_palet_iso: number,
    pricelist?: {
        brick_id: number,
        price_inv: number,
        price_cash: number,
        price_avans_banka: number,
        price_avans_cash: number,
    }[],
}

export interface City {
    city: string;
    phone: string;
}

export type BankaCashType = "BANKA" | "CASH" | null

export interface AccountOperation {
    id: number;
    sales_id: number;
    timestamp: string;
    account_type: PAY_TYPE;
    operation: OPERATION_TYPE;
    description: string;
    amount: number;
    amountType: BankaCashType;
    is_avans: 0 | 1;
    approvedPaletes: number;
    approvedPaletesAmount: number;
    scrappedPaletes: number;
    approvedPaletes_iso: number;
    approvedPaletesAmount_iso: number;
    scrappedPaletes_iso: number;
}

export enum OPERATION_TYPE {
    DEPOSIT = 'DEPOSIT',
    WITHDRAW = 'WITHDRAW'
}

export interface SaleItem {
    id: number,
    sales_id: number,
    property_id: number,
    bag_type: number,
    palet_qty: number,
    qty_in_palet: number,
    pelet_type: number,
    bag_qty: number,
    total: number
}

export interface Standarts {
    grinding_bucket_clay_kg: number;
    grinding_bucket_sludge_kg: number;

}
