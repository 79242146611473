import React, {useEffect, useMemo, useState} from "react";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isLoading, isSuccess, isError, showErrorsInToast, zeroToEmptyString, getDaysInMonth} from "../../common";
import {Spinner, Table} from "react-bootstrap";


export type BonusPlusReportType = {
    reportParameters: {
        bonus_unloadBonus: number;
        bonus_unloadWasteBonus: number;
        bonus_orderingWasteBonus: number;
        bonus_paletsBonus: number;
        v1: number;
        v2: number;
        v2_type: number;
        v3: number;
        v3_total: number;
        v3_waste: number;
        v6: number;
        v6_total: number;
        v6_waste: number;
        vExtruder: number;
        vGrinding: number;
        vHeater: number;
        vOrdering: number;
        vOven: number;
        vUnloading: number;
        bonus: number;
    }[],
    users: {
        user: {
            id: number,
            name: string,
            position: string,
        },
        bonuses: {
            [key: string]: number
        }
    }[]
}

export interface BonusPlusReport {
    id: number,
    name: string,
    position: string,
    maxBonus: number,
}

export const API_PATH_REPORT = 'admin/bonus-plus/current';

interface BonusPlusCurrentComponentProps {
    year: string,
    month: string,
}

export function BonusPlusCurrentComponent({year, month}: BonusPlusCurrentComponentProps) {

    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<BonusPlusReportType | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

    const loadReport = () => {
        setLoading(true);
        API.doGetRequest(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    const data = apiCall.data.data as BonusPlusReportType;
                    setReportData(data);

                    // data.users.forEach((u) => {
                    //     console.log(u.user);
                    //     Object.keys(u.bonuses).forEach((key) => {
                    //         console.log(key, u.bonuses[key as any]);
                    //     });
                    // });
                } else if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "ВЪЗНИКНА ГРЕШКА");
                }
            },
            API_PATH_REPORT + '/' + month + '/' + year
        );
    }

    useEffect(() => {
        loadReport();
    }, []);

    const reportKeys = useMemo(() => {
        return reportData && reportData.reportParameters ? Object.keys(reportData.reportParameters) : [];
    }, [reportData]);

    function getBrixName(v2_type: number | undefined) {
        switch (v2_type) {
            case 0:
                return "Плътна";
            case 1:
                return "Термо";
            case 2:
                return "ISOBRIX+12";
            case 3:
                return "ISOBRIX+25";
            default:
                return "";
        }
    }

    console.log(reportData);
    console.log(reportKeys);

    return (
        <>
            <Table bordered striped size={"sm"}>
                <thead>
                <tr>
                    <th>Служител</th>
                    <th className={"text-end"}>Бонус към момента</th>
                    <th className={"text-end"}>Бонус за месеца</th>
                    <th className={"text-end"}>Средно за ден</th>
                </tr>
                </thead>
                <tbody>
                {
                    loading &&
                    <tr>
                        <td className={"text_center"} colSpan={4}><Spinner animation={"border"}/></td>
                    </tr>
                }
                {
                    !loading && reportData && reportData.users &&
                    reportData.users.map(
                        (u, i) => {
                            const currentObj = u.bonuses.current;
                            const bonusKeys = Object.keys(u.bonuses);
                            const daysCnt = bonusKeys.filter(
                                (key) => !key.includes("current")
                            ).length / 5;

                            const tdClass = selectedUserId === u.user.id ? "fw-bold text-white " : "fw-bold ";

                            if(currentObj) {
                                const current = currentObj;
                                const cnt = Object.keys(u.bonuses).length / 5;
                                const avg = cnt > 1 ? (current / (cnt - 1)) : 0;
                                const monthBonus = avg * daysCnt;

                                return (
                                    <tr key={i} onClick={() => {setSelectedUserId(u.user.id);}}
                                            className={selectedUserId === u.user.id ? "taifunTheme" : ""}
                                    >
                                        <td className={tdClass}>{u.user.name}</td>
                                        <td className={tdClass + "text-end"}>{current?.toFixed(0)}</td>
                                        <td className={tdClass + "text-end"}>{monthBonus.toFixed(0)}</td>
                                        <td className={tdClass + "text-end"}>{avg.toFixed(0)}</td>
                                    </tr>
                                );
                            } else {
                                return (
                                    <tr key={i} onClick={() => {
                                        setSelectedUserId(u.user.id);
                                    }}
                                        className={selectedUserId === u.user.id ? "taifunTheme" : ""}
                                    >
                                        <td>{u.user.name}</td>
                                        <td className={"text-end"}></td>
                                        <td className={"text-end"}></td>
                                        <td className={"text-end"}></td>
                                    </tr>
                                );
                            }
                        })
                }
                </tbody>
            </Table>

            <Table bordered striped size={"sm"}>
                <thead>
                {
                    selectedUserId ?
                        <>
                        <tr>
                            <th rowSpan={2}>Ден</th>
                            <th rowSpan={2}>Тухла</th>
                            <th className={"text-end"} colSpan={2}>Производителност, брой тухли</th>
                            <th className={"text-end"} colSpan={2}>Брак разтоварване, брой тухли</th>
                            <th className={"text-end"} colSpan={2}>Брак редене, брой тухли</th>
                            <th className={"text-end"} colSpan={2}>Разход пелети, кг</th>
                            <th className={"text-end"} rowSpan={2}>Бонус</th>
                        </tr>
                            <tr>
                                <th className={"text-end"}>Стойност</th>
                                <th className={"text-end"}>Бонус</th>
                                <th className={"text-end"}>Стойност</th>
                                <th className={"text-end"}>Бонус</th>
                                <th className={"text-end"}>Стойност</th>
                                <th className={"text-end"}>Бонус</th>
                                <th className={"text-end"}>Стойност</th>
                                <th className={"text-end"}>Бонус</th>
                            </tr>
                        </>
                        :
                        <tr>
                            <th>Ден</th>
                            <th>Тухла</th>
                            <th className={"text-end"}>Производителност, брой тухли</th>
                            <th className={"text-end"}>Брак разтоварване, брой тухли</th>
                            <th className={"text-end"}>Брак редене, брой тухли</th>
                            <th className={"text-end"}>Разход пелети, кг</th>
                        </tr>
                }

                </thead>
                <tbody>
                {
                    loading &&
                    <tr>
                        <td className={"text_center"} colSpan={6}><Spinner animation={"border"}/></td>
                    </tr>
                }
                {
                    !loading && reportData && reportData.reportParameters &&
                    reportKeys.map(
                        (key, i) => {
                            const keyName = key === "current" ? "ОБЩО:" : key;
                            const r = reportData.reportParameters[key as any];
                            const selectedUserData = reportData.users.find((u) => u.user.id === selectedUserId);
                            const selectedUserBonuses = selectedUserData?.bonuses;

                            if (r?.v2_type === -1) {
                                const dayBonus = selectedUserBonuses && selectedUserBonuses[keyName] ? selectedUserBonuses[keyName] : 0;
                                return (
                                    <tr key={i}>
                                        <td>{key}</td>
                                        <td colSpan={selectedUserId ? 7: 4} className={"bg-secondary-light"}>Смяна на вид тухла</td>
                                        <td className={"text-end"}>{r?.v1}</td>
                                        <td/>
                                        { selectedUserId && <td className={"text-end"}>{dayBonus.toFixed(0)}</td> }
                                    </tr>
                                )
                            }
                            return (
                                    selectedUserId && selectedUserBonuses ?
                                        <tr key={i} className={keyName === "ОБЩО:" ? "fw-bold text-danger" : ""}>
                                            <td>{keyName}</td>
                                            <td>{getBrixName(r?.v2_type)}</td>
                                            <td className={"text-end"}>{r?.v3_total}</td>
                                            <td className={"text-end"}>{selectedUserBonuses[`${key}_unloadBonus`]?.toFixed(0)}</td>
                                            <td className={"text-end"}>{r?.v3_waste}</td>
                                            <td className={"text-end"}>{selectedUserBonuses[`${key}_unloadWasteBonus`]?.toFixed(0)}</td>
                                            <td className={"text-end"}>{r?.v6_waste}</td>
                                            <td className={"text-end"}>{selectedUserBonuses[`${key}_orderingWasteBonus`]?.toFixed(0)}</td>
                                            <td className={"text-end"}>{r?.v1}</td>
                                            <td className={"text-end"}>{selectedUserBonuses[`${key}_paletsBonus`]?.toFixed(0)}</td>
                                            <td className={"text-end bg-secondary-light"}>{selectedUserBonuses[key]?.toFixed(0)}</td>
                                        </tr>
                                        :
                                        <tr key={i} className={keyName === "ОБЩО:" ? "fw-bold text-danger" : ""}>
                                            <td>{keyName}</td>
                                            <td>{getBrixName(r?.v2_type)}</td>
                                            <td className={"text-end"}>{r?.v3_total}</td>
                                            <td className={"text-end"}>{r?.v3_waste}</td>
                                            <td className={"text-end"}>{r?.v6_waste}</td>
                                            <td className={"text-end"}>{r?.v1}</td>
                                            {selectedUserId &&
                                                <td className={"text-end"}>{zeroToEmptyString(r?.bonus.toFixed(0))}</td>}
                                        </tr>
                            );
                        })
                }
                </tbody>
            </Table>
        </>
    )
}
