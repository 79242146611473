import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, Client, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {
    isLoading,
    isSuccess,
    minutesToHours,
    numberWithCommas,
    zeroToEmptyString,
    zeroToEmptyStringAsCurrency,
    zeroToEmptyStringAsCurrencyWithCommas
} from "../../common";
import moment, {Moment} from "moment";
import {EditClientAccountModal} from "../clients/EditClientAccountModal";


interface ClientsBalanceReportPageState {
    loading: boolean,
    periodTo: string,
    data: any,
    editClientAccount: Client | null,
}

interface ClientsBalanceReportPageProps {
    periodTo: string,
    showAvance: boolean,
}

export class ClientsBalanceReportPage extends React.Component<ClientsBalanceReportPageProps, ClientsBalanceReportPageState> {

    constructor(props: ClientsBalanceReportPageProps) {
        super(props);
        this.state = {
            periodTo: props.periodTo,
            loading: false,
            data: null,
            editClientAccount: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.getAccountBalance(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.processData(apiCall.data.data.list);
                }
            }, {
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        const _data: any[] = [];
        Object.keys(data).forEach( k => {
            const item = data[k];
            item.client.cities = JSON.parse(item.client.cities);
            _data.push(item)
        } );
        this.setState({data: _data});
    }

    componentDidUpdate(prevProps: Readonly<ClientsBalanceReportPageProps>, prevState: Readonly<ClientsBalanceReportPageState>, snapshot?: any) {
        if (
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodTo: this.props.periodTo
            });
            this.loadData();
        }
    }


    renderReport(data: any) {
        return '';
    }

    renderReports() {
        const total = {
            BANKA: 0,
            CASH: 0,
            CREDIT: 0,
            LOGISTIC: 0
        }

        const _data = this.state.data || [];
        _data.sort(
            (i1: any, i2: any) => {
                let t1 = 0; let t2 = 0;

                if(this.props.showAvance) {
                    t1 = i1.balances.AVANS_BANKA + i1.balances.AVANS_CASH + i1.balances.AVANS_CREDIT + i1.balances.CREDIT;
                    t2 = i2.balances.AVANS_BANKA + i2.balances.AVANS_CASH + i2.balances.AVANS_CREDIT + i2.balances.CREDIT;
                    return t1 > t2 ? -1 : (t1 < t2 ? 1 : 0);
                } else {
                    t1 = i1.balances.BANKA + i1.balances.CASH;
                    t2 = i2.balances.BANKA + i2.balances.CASH;
                    return t1 > t2 ? 1 : (t1 < t2 ? -1 : 0);
                }
            }
        );

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th rowSpan={2}>№</th>
                <th rowSpan={2} className={"text-start"}>Клиент</th>
                <th rowSpan={2} className={"text-start"}>Градове</th>
                <th colSpan={3}>Баланс (лв)</th>
            </tr>
            <tr>
                <th className={"text-end"}>Банка</th>
                <th className={"text-end"}>ОП</th>
                {
                    this.props.showAvance ?
                        <th className={"text-end"}>Кредитно</th> :
                        <th className={"text-end"}>Транспорт</th>
                }
            </tr>
            </thead>
            <tbody>
            {
                _data
                    .filter(
                        (item: any) => {
                            if(this.props.showAvance) {
                                return item.balances.AVANS_BANKA > 0 || item.balances.AVANS_CASH > 0 || item.balances.AVANS_CREDIT > 0
                                    || item.balances.CREDIT > 0;
                            } else {
                                return item.balances.BANKA < 0 || item.balances.CASH < 0 || item.balances.AVANS_LOGISTIC < 0
                                    || item.balances.LOGISTIC < 0;
                            }
                        }
                    ).map(
                    (item: any, idx: number) => {

                        if(this.props.showAvance) {
                            total.BANKA +=  item.balances.AVANS_BANKA > 0 ? item.balances.AVANS_BANKA : 0;
                            total.CASH +=   item.balances.AVANS_CASH > 0 ? item.balances.AVANS_CASH : 0;
                            total.CREDIT +=  (item.balances.AVANS_CREDIT + item.balances.CREDIT > 0) ? item.balances.AVANS_CREDIT + item.balances.CREDIT : 0;
                        } else {
                            total.BANKA +=  item.balances.BANKA < 0 ? item.balances.BANKA : 0;
                            total.CASH +=  item.balances.CASH < 0 ? item.balances.CASH : 0;
                            total.LOGISTIC +=  (item.balances.AVANS_LOGISTIC + item.balances.LOGISTIC) < 0 ? (item.balances.AVANS_LOGISTIC + item.balances.LOGISTIC) : 0
                        }

                        return <tr key={item.client.id}>
                            <td>{(idx + 1)}</td>
                            <td className={"text-start"}>
                                <a href={"#"}
                                   onClick={(e) =>
                                   {e.preventDefault(); this.setState({editClientAccount: item.client as Client})}}
                                >
                                    {item.client.name}</a></td>
                            <td className={"text-start"}>{item.client.cities.map((c: any) => c.city + (c.phone ? ' (' + c.phone + ')' : '')).join('; ')}</td>
                            {
                                this.props.showAvance ?
                                    <>
                                        <td className={"text-end"}>
                                            {
                                                item.balances.AVANS_BANKA > 0 ? zeroToEmptyStringAsCurrencyWithCommas(item.balances.AVANS_BANKA) : ""
                                            }
                                        </td>
                                        <td className={"text-end"}>
                                            {
                                                item.balances.AVANS_CASH > 0 ? zeroToEmptyStringAsCurrencyWithCommas(item.balances.AVANS_CASH) : ""
                                            }
                                        </td>
                                        <td className={"text-end"}>
                                            {
                                                (item.balances.AVANS_CREDIT + item.balances.CREDIT) > 0 ?
                                                    zeroToEmptyStringAsCurrencyWithCommas(item.balances.AVANS_CREDIT + item.balances.CREDIT) : ""
                                            }
                                        </td>
                                    </> :
                                    <>
                                        <td className={"text-end"}>{ (item.balances.BANKA < 0) ? zeroToEmptyStringAsCurrencyWithCommas(item.balances.BANKA) : "" }</td>
                                        <td className={"text-end"}>{ (item.balances.CASH < 0) ? zeroToEmptyStringAsCurrencyWithCommas(item.balances.CASH) : "" }</td>
                                        <td className={"text-end"}>{
                                            ( item.balances.AVANS_LOGISTIC + item.balances.LOGISTIC < 0) ?
                                            zeroToEmptyStringAsCurrencyWithCommas(item.balances.AVANS_LOGISTIC + item.balances.LOGISTIC) : ""
                                        }</td>
                                    </>
                            }
                        </tr>
                    }
                )
            }
            <tr className={"bg-secondary text-light font-weight-bold"}>
                <td colSpan={3}>Всичко</td>
                <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithCommas(total.BANKA.toString())}</td>
                <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithCommas(total.CASH.toString())}</td>
                {
                    this.props.showAvance ?
                        <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithCommas(total.CREDIT.toString())}</td> :
                        <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithCommas(total.LOGISTIC.toString())}</td>
                }
            </tr>
            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
                {
                    this.state.editClientAccount &&
                    <EditClientAccountModal client={this.state.editClientAccount}
                                            onClose={() => {this.setState({editClientAccount: null})}}/>
                }
            </>
        );
    }
}
