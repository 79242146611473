import React, {useEffect, useState} from "react";
import {AccountOperation, API_CALL, Client, OPERATION_TYPE, PARAMETERS, PAY_TYPE, Property} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError} from "../../common";
import {Button, Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";
import {forEach} from "react-bootstrap/ElementChildren";

export interface EditClientPricesDetailsProps {
    client: Client;
    onCancel: () => any;
    onRefresh?: () => any;
}

export default function EditClientPricesDetails({client, onCancel, onRefresh}: EditClientPricesDetailsProps) {

    const [loading, setLoading] = useState(2);
    const [brickTypes, setBrickTypes] = useState<Property[]>([]);
    const [priceList, setPriceList] = useState<{brick_id: number, price_inv: string, price_cash: string, price_avans_banka: string, price_avans_cash: string}[]>([]);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = function () {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setLoading(loading-1);
                    setBrickTypes(apiCall.data.data.items?.values.map(
                        (v: any) => {
                            return {id: v.id, name: v.value} as Property
                        }
                    ));
                }
            }, PARAMETERS.BRICKS
        );

        API.getClientPriceList(
            (apiCall: API_CALL) => {
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    setLoading(loading-1);
                    setPriceList(apiCall.data.data.pricelist);
                }
            }, client.id
        )
    }

    const cancelClient = function () {
        onCancel();
    }

    const getPrice = function (b: Property): {price_inv: string, price_cash: string, price_avans_banka: string, price_avans_cash: string} {
        for(let i = 0; i < priceList.length; i++) {
            if(priceList[i].brick_id === b.id)
                return {
                    price_inv: priceList[i].price_inv,
                    price_cash: priceList[i].price_cash,
                    price_avans_banka: priceList[i].price_avans_banka,
                    price_avans_cash: priceList[i].price_avans_cash,
                };
        }
        return {price_inv: '0', price_cash: '0', price_avans_banka: '0', price_avans_cash: '0'};
    }

    const getInvPrice = function (b: Property) {
        return getPrice(b).price_inv;
    }

    const getCashPrice = function (b: Property) {
        return getPrice(b).price_cash;
    }
    const getAvansBankaPrice = function (b: Property) {
        return getPrice(b).price_avans_banka;
    }

    const getAvansCashPrice = function (b: Property) {
        return getPrice(b).price_avans_cash;
    }


    const updatePrice = function (b: Property, newInvPrice: string|null, newCashPrice: string|null,
                                  newAvansBankaPrice: string|null, newAvansCashPrice: string|null) {
        for(let i = 0; i < priceList.length; i++) {
            if(priceList[i].brick_id == b.id) {
                if(newInvPrice) priceList[i].price_inv = newInvPrice;
                if(newCashPrice) priceList[i].price_cash = newCashPrice;
                if(newAvansBankaPrice) priceList[i].price_avans_banka = newAvansBankaPrice;
                if(newAvansCashPrice) priceList[i].price_avans_cash = newAvansCashPrice;
                setPriceList([...priceList]);
                return;
            }
        }
        priceList.push({brick_id: b.id, price_inv: newInvPrice || '0', price_cash: newCashPrice || '0',
            price_avans_banka: newAvansBankaPrice || '0', price_avans_cash: newAvansCashPrice || '0'});
        setPriceList([...priceList]);
    }

    const setPrice = function (b: Property) {
        for(let i = 0; i < priceList.length; i++) {
            let p = parseFloat(priceList[i].price_inv.replace(',', '.'));
            if(isNaN(p)) priceList[i].price_inv = '0';
            else priceList[i].price_inv = p.toFixed(4);

            p = parseFloat(priceList[i].price_cash.replace(',', '.'));
            if(isNaN(p)) priceList[i].price_cash = '0';
            else priceList[i].price_cash = p.toFixed(4);
        }
        setPriceList([...priceList]);
    }

    function saveChanges() {
        setLoading(prev => prev + 1);

        API.setClientPriceList(
            (apiCall: API_CALL) => {
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    setLoading(loading-1);
                    showMsgInToast('Ценоразписа е запазен успешно!');
                }
            }, client.id, {priceList: priceList}
        )

    }

    if(API.userHasModul(29))
        return (
            <div className={"col align-content-between"}>
                <div className="row">
                    <div className="col-12 mr-0 pr-0">
                        <div className={"row d-block w-100"} style={{maxHeight: "50vh", overflow: "auto"}}>
                            <div className={"col-12"}>
                                <table className={"table table-sm m-0 p-0"}>
                                    <thead>
                                    <tr>
                                        <th rowSpan={2} className={"align-middle"}>Вид тухла</th>
                                        <th colSpan={4} className={"text-center"}>Цена</th>
                                    </tr>
                                    <tr>
                                        <th className={"text-end"}>Фактура</th>
                                        <th className={"text-end"}>Отл.плащане</th>
                                        <th className={"text-end"}>Aванс банка</th>
                                        <th className={"text-end"}>Aванс отл.пл.</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        brickTypes.map(
                                            b =>
                                                <tr key={b.id}>
                                                    <td>{b?.name}</td>
                                                    <td>
                                                        <input
                                                            className={"form-control small"}
                                                            key={b.id}
                                                            value={getInvPrice(b)}
                                                            onChange={e => updatePrice(b, e.target.value, null, null, null)}
                                                            onBlur={() => setPrice(b)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={"form-control small"}
                                                            key={b.id}
                                                            value={getCashPrice(b)}
                                                            onChange={e => updatePrice(b, null, e.target.value, null, null)}
                                                            onBlur={() => setPrice(b)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={"form-control small"}
                                                            key={b.id}
                                                            value={getAvansBankaPrice(b)}
                                                            onChange={e => updatePrice(b, null, null, e.target.value, null)}
                                                            onBlur={() => setPrice(b)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={"form-control small"}
                                                            key={b.id}
                                                            value={getAvansCashPrice(b)}
                                                            onChange={e => updatePrice(b, null, null, null, e.target.value)}
                                                            onBlur={() => setPrice(b)}
                                                        />
                                                    </td>
                                                </tr>

                                        )
                                    }
                                    <tr>
                                        <td colSpan={3} className={"text-end"}>
                                            <Button onClick={() => saveChanges()}>
                                                Запази стойностите
                                            </Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    return (
        <div className={"col align-content-between"}>
            <div className="row">
                <div className="col-12 mr-0 pr-0">
                    <strong className={"text-danger"}>Нямате достъп до този модул!</strong>
                </div>
            </div>
        </div>
    )
}
