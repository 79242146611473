import {Form} from "react-bootstrap";
import React from "react";
import {BankaCashType, PAY_TYPE} from "../types";


interface SelectAmountTypeProps {
    value: BankaCashType | string;
    onChange: (value: BankaCashType) => void;
    fullVersion?: boolean;
}

export default function SelectAmountType({value, onChange, fullVersion}: SelectAmountTypeProps) {
    return (
        <Form.Select value={value as string}
                     onChange={event => onChange(event.target.value as BankaCashType)}
        >
            <option value={""}></option>
            <option value={PAY_TYPE.BANKA}>БАНКА</option>
            <option value={PAY_TYPE.CASH}>ОП</option>
            {
                fullVersion &&
                <>
                    <option value={PAY_TYPE.CASH_INV}>КЕШ ФАКТУРА</option>
                    <option value={PAY_TYPE.CREDIT}>КРЕДИТНО</option>
                </>
            }
        </Form.Select>
    );
}
