import { BlockTitleComponent } from "../common/BlockTitleComponent";
import {Button, ButtonGroup, ButtonToolbar, Col, Row, Table} from "react-bootstrap";
import React from "react";

export interface ExclusionItem {
    forDate: string;
    hasMultipleBricks: 0 | 1;
    hasEmergency: 0 | 1;
}

export function BonusPlusExclusionsComponent({ exclusions, onChangeData }: { exclusions: Array<ExclusionItem>, onChangeData: (newData: Array<ExclusionItem>) => any }) {

    function updateData() {
        onChangeData([...exclusions]);
    }

    const middle = Math.round(exclusions.length / 2);

    return (
        <div className={"overflow-auto"}>
            <BlockTitleComponent title={"Изключения за месеца"} />
            <Row className={"w-100"}>
                <Col xs={"auto"}>
                    <Table className={"w-600px"} size={"sm"}>
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Извънредно положение</th>
                            <th>Различни видове тухли</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            exclusions.slice(0, middle).map(
                                e => <tr key={e.forDate}>
                                    <td> {e.forDate} </td>
                                    <td className={"text-center"}>
                                        <YesNoButton value={e.hasEmergency} onChange={nv => {
                                            e.hasEmergency = nv;
                                            updateData();
                                        }} />
                                    </td>
                                    <td className={"text-center"}>
                                        <YesNoButton value={e.hasMultipleBricks} onChange={nv => {
                                            e.hasMultipleBricks = nv;
                                            updateData();
                                        }} />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table className={"w-600px"} size={"sm"}>
                        <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Извънредно положение</th>
                            <th>Различни видове тухли</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            exclusions.slice(middle).map(
                                e => <tr key={e.forDate}>
                                    <td> {e.forDate} </td>
                                    <td className={"text-center"}>
                                        <YesNoButton value={e.hasEmergency} onChange={nv => {
                                            e.hasEmergency = nv;
                                            updateData();
                                        }} />
                                    </td>
                                    <td className={"text-center"}>
                                        <YesNoButton value={e.hasMultipleBricks} onChange={nv => {
                                            e.hasMultipleBricks = nv;
                                            updateData();
                                        }} />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>

                </Col>
            </Row>
        </div>
    )
}

export function YesNoButton({value, onChange}: {value: 0 | 1, onChange: (newValue: 0 | 1) => any}) {
    return (
        // <ButtonToolbar aria-label="Toolbar with button groups">
        //     <ButtonGroup className="me-2" aria-label="First group">
                <Button size={"sm"} variant={value === 0 ? 'outline-secondary' : 'danger'}
                        onClick={() => onChange(value == 0 ? 1 : 0)} >Да</Button>
                /*<Button variant={value === 1 ? 'outline-secondary' : 'primary'} onClick={() => onChange(0)} >Не</Button>*/
        //     </ButtonGroup>
        // </ButtonToolbar>
    )
}
